.video-container {
    padding-top: 50px;
}

.responsive-iframe {
    border: none;
    width: 640px; /* Default width */
    height: 360px; /* Default height */
}

/* For devices with a width of up to 600px, adjust iframe size */
@media (max-width: 600px) {
    .responsive-iframe {
        width: 320px; /* 50% of 640px */
        height: 180px; /* 50% of 360px */
    }
}
