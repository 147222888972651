@font-face {
  font-family: "neue-kabel";
  src: local("neue-kabel"),
   url("./fonts/Neue\ Kabel\ W01\ Regular.ttf") format("truetype");
  }

.g-recaptcha {
  display: none;
}

body {
  margin: 0;
  font-family: 'muli', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #fffef8; */
  background: rgba(255, 230, 170, 0.081);
  /* background: radial-gradient(circle, rgba(255,230,170,0.5) 0%, rgba(255,221,141,0.5) 15%, rgba(255,255,255,0) 25%); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p.amplify-text {
  font-family: 'muli', 'neue-kabel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: x-large;
}

button.amplify-button--link {
  font-size: larger;
}

p {
  font-family: 'muli', 'neue-kabel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: x-large;
}

li {
  font-family: 'muli', 'neue-kabel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: x-large;
}

h1.amplify-heading {
  font-weight: 900;
  font-family: 'muli', sans-serif;
  font-size: 3.3em;
}

h2.amplify-heading {
  font-weight: 800;
  font-family: 'muli', sans-serif;
  font-size: 2.0em;
}

h3.amplify-heading {
  font-weight: 700;
  font-family: 'muli', sans-serif;
  font-size: 1.5em;
}

h4.amplify-heading {
  font-weight: 700;
  font-family: 'muli', sans-serif;
}

@media only screen and (max-width: 600px) {
  h1.amplify-heading {
    font-weight: 900;
    font-family: 'muli', sans-serif;
    text-shadow: 0px 1px, 1px 0px, 1px 1px;
    font-size: 22pt;
  }

  p.amplify-text {
    font-family: 'muli', 'neue-kabel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: medium;
  }
}

/*-------- iPads (portrait and landscape) --------*/
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) {
  body {
      background-attachment: initial;
  }
}