.react-multi-carousel-item {
    margin: 0 1rem;
}

.react-multi-carousel-list {
    height: 100%;
}

.react-multi-carousel-track {
    height: 100%
}

@media (min-width: 600px)  {
    .react-multi-carousel-list {
        justify-content: center;
    }
}