.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rotate-center {
	-webkit-animation: rotate-center 30s infinite linear;
	        animation: rotate-center 30s infinite linear;
}

.pulse {
	-webkit-animation: pulse 15s infinite;
	        animation: pulse 15s infinite;
}

.loading {
    -webkit-animation: App-logo-spin 1s infinite linear;
	        animation: App-logo-spin 1s infinite linear;
}

.loading {
    -webkit-animation: App-logo-spin 1s infinite linear;
	        animation: App-logo-spin 1s infinite linear;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-8-4 9:42:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes pulse {
	0% {
		transform: scale(0.85);
	}
	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(0.85);
	}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.moving-gradient {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}
