.Chat {
    display: flex;
    justify-content: space-between; /* This will ensure the video and chat are spaced apart. */
    align-items: center; /* This will vertically align them. */
    height: 100%; /* or any other height you might want */
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

.ChatBox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #E0E8F1; /* Subtle pale blue background */
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    display: none;
    z-index: 9999;
}

#talk-video {
    flex: 1; /* takes up the available space but shares it with the chat component */
    max-width: 600px; /* as given in your example */
    object-fit: cover; /* ensures the video keeps its aspect ratio */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}


.chat-container {
    border: 1px solid #ddd;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    z-index: 9999;
    flex: 1;
}

.messages-container {
    max-height: 450px;
    overflow-y: auto;
    margin-bottom: 20px;
    z-index: 9999;
}

.message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    text-align: left;
    z-index: 9999;
}

.message.user {
    background-color: #f1f1f1;
    z-index: 9999;
}

.input-container {
    display: flex;
    justify-content: space-between;
    z-index: 9999;
}

input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

textarea {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.markdown-container {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    padding: 20px;
    border-radius: 6px;
    background-color: #f9f9f9;
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: bold;
}

.markdown-container h1 {
    font-size: 2em;
}

.markdown-container h2 {
    font-size: 1.5em;
}

.markdown-container h3 {
    font-size: 1.25em;
}

.markdown-container h4 {
    font-size: 1em;
}

.markdown-container h5 {
    font-size: 0.9em;
}

.markdown-container h6 {
    font-size: 0.8em;
}

.markdown-container p {
    margin-bottom: 16px;
    font-size: 1.2em;
}

.markdown-container li {
    font-size: 1.2em;
}

.markdown-container a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s;
}

.markdown-container a:hover {
    text-decoration: underline;
}

.markdown-container ul,
.markdown-container ol {
    margin-bottom: 16px;
    padding-left: 20px;
}

.markdown-container li {
    margin-bottom: 8px;
}

.markdown-container blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    border-left: 3px solid #ddd;
    background-color: #eee;
}

.markdown-container pre {
    background-color: #333;
    padding: 16px;
    overflow-x: auto;
    border-radius: 6px;
}

.markdown-container code {
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
    padding: 2px 4px;
    background-color: #efefef;
    border-radius: 3px;
}

.markdown-container pre > code {
    display: block;
    padding: 0;
    background-color: transparent;
    color: #f9f9f9;
}

/* If you have tables in your Markdown */
.markdown-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.markdown-container table, th, td {
    border: 1px solid #ddd;
}

.markdown-container th, td {
    padding: 8px 12px;
}

.markdown-container thead {
    background-color: #f2f2f2;
}

#talk-video-preview-notifier {
    position: fixed;
    bottom: 170px;
    right: 10px;
    text-align: center;
    z-index: 10000;
    border: 2px solid #000;
    border-radius: 5px;
    padding: 6px;
    overflow: hidden;
    background-color: white;
    transition: transform 0.3s, border-color 0.3s, opacity 1s, transform 1s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);  /* Gives depth */
    transform: translate(0, 0) scale(1);
}

#talk-video-preview-container {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 9999;
    border: 2px solid #000;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, border-color 0.3s, opacity 1s, transform 1s;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);  /* Gives depth */
    animation: pulse 1.5s infinite;
    transform: translate(0, 0) scale(1);
}

#talk-video-preview-container.fade-out {
    opacity: 0;
    /* Adjust X, Y, and Z values below as needed */
    transform: translate(-240px, -240px) scale(2);
}


#talk-video-preview-container:hover {
    border-color: #0077ff;  /* Change to any other color you like */
    transform: scale(1.05);  /* Slightly scales up the video preview */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.02);
    }
    100% {
        transform: scale(1);
    }
}

#talk-video {
    transition: opacity 0.5s, visibility 0.5s;
}

@keyframes shake {
    0% { transform: translateX(0); }
    10% { transform: translateX(-2px); }
    20% { transform: translateX(2px); }
    20% { transform: translateX(-2px); }
    40% { transform: translateX(2px); }
    20% { transform: translateX(-2px); }
    60% { transform: translateX(2px); }
    70% { transform: translateX(-2px); }
    80% { transform: translateX(2px); }
    90% { transform: translateX(-2px); }
    100% { transform: translateX(2px); }
}

.shake-on-hover:hover {
    animation: shake 0.8s cubic-bezier(.36,.07,.19,.97) both;
}

.inner-container {
    position: relative;
    width: 120px;  /* The width of the video */
    height: 120px; /* The height of the video */
    background-color: white;
}

.bubble {
    position: absolute;
    bottom: 110px;
    left: 50%;  /* center the bubble */
    transform: translateX(-50%); /* adjust for perfect centering */
    width: 90%;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: none;
    text-align: center;
}

.inner-container:hover .bubble {
    display: block;
}

.send-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
    outline: none;  /* Remove the default outline */
    animation: none;
}

.send-button.pulsate {
    animation: pulsate 1s infinite;
}

.send-button.recording {
    background-color: #b72020;
}

/* .send-button:hover {
    background-color: #45a049;
} */

.send-button svg {  /* This will style the SendIcon specifically */
    fill: white;  /* Icon color */
}

.white-button {
    background-color: #FFFFFF;
    border: thin solid #282c34;
    color: black;
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color 0.3s;
    outline: none;  /* Remove the default outline */
    animation: none;
}

.white-button.pulsate {
    animation: pulsate 1s infinite;
}

.white-button:hover {
    animation: pulsate 1s infinite;
}

@keyframes pulsate {
    0% {
        transform: scale(1);
        filter: saturate(100%);
    }
    50% {
        transform: scale(1.2);
        filter: saturate(150%); /* Adjust the saturation value as needed */
    }
    100% {
        transform: scale(1);
        filter: saturate(100%);
    }
}




