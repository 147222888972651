.avatar-container {
    position: relative;
    display: inline-block;
}

.speech-bubble {
    position: relative;
    top: 10%;
    left: 60%;
    /* transform: translateX(-50%); */
    padding: 10px 20px;
    min-width: 120px;
    max-width: 120px;
    background-color: white;
    border: 2px solid #000000; /* Border color */
    border-radius: 20px;
    font-size: 36px;
    color: #000000; /* Text color */
    text-align: center;
    animation: fade 1s infinite alternate;
    z-index: 200;
}

.speech-bubble::before,
.speech-bubble::after {
    content: "";
    position: absolute;
    background-color: white;
    border: 2px solid #000000; /* Border color */
}

/* Tail of the speech bubble */
.speech-bubble::before {
    width: 20px;
    height: 20px;
    bottom: -10px;
    left: 20px;
    border-radius: 50%;
    transform: rotate(45deg);
}

/* Optional: Small circle to smooth out where tail meets bubble */
.speech-bubble::after {
    width: 10px;
    height: 10px;
    bottom: -20px;
    left: 10px;
    border-radius: 50%;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0.9;
    }
}
